<template>
  <div>
    <seats-map-canvas />
  </div>
</template>
<script>
import SeatsMapCanvas from './seatsMapCanvas/Index'
export default {
  components: {
    SeatsMapCanvas,
  },
}
</script>

